import styled from '@emotion/styled'
import { classNames } from '@common/utils/helpers'
import { FC, HTMLAttributes } from 'react'

interface DashboardHeaderProps extends HTMLAttributes<HTMLElement> {
  className?: string
}

type DashboardHeader = FC<React.PropsWithChildren<DashboardHeaderProps>>

const DashboardHeaderWrapper = styled.section<{
  className: string
}>`
  &.pq-dashboard-header {
    grid-area: head;
  }
`

const DashboardHeader: DashboardHeader = (props) => {
  const { children, className, ...rest } = props
  const classes = classNames('pq-dashboard-header', className || '')

  return (
    <DashboardHeaderWrapper className={classes} {...rest}>
      {children}
    </DashboardHeaderWrapper>
  )
}

export { DashboardHeader }
