import { DashboardFooter as Footer } from './dashboard-footer'
import { HeadCommon } from '@common/components/head'
import { DashboardHeader as Header } from './dashboard-header'
import { IHeadCommonProps } from '@common/components/head/types'
import { Main } from './dashboard-main'
import { PropsWithChildren } from 'react'
import { Sidebar } from '@common/components/sidebar'
import { classNames } from '@common/utils/helpers'
import styled from '@emotion/styled'

interface DashboardProps extends Partial<IHeadCommonProps> {
  className?: string | undefined
}

type Dashboard = DashboardProps

const DashboardWrapper = styled.div`
  &.pq-dashboard {
    display: grid;
    grid-template-columns: 40% 1fr;
    grid-template-rows: 72px 1fr;
    grid-template-areas:
      'sidebar head'
      'sidebar main'
      'sidebar footer';
    height: 100%;
    min-height: 100vh;
  }
`

const Dashboard = (props: PropsWithChildren<Dashboard>) => {
  const { children, className, metaTitleFormatMessage } = props
  const classes = classNames('pq-dashboard', className || '')
  return (
    <DashboardWrapper className={classes}>
      <HeadCommon metaTitleFormatMessage={metaTitleFormatMessage} />
      {children}
    </DashboardWrapper>
  )
}

Dashboard.Sidebar = Sidebar
Dashboard.Footer = Footer
Dashboard.Header = Header
Dashboard.Main = Main

export { Dashboard }
