import styled from '@emotion/styled'
import { classNames } from '@common/utils/helpers'
import { FC, HTMLAttributes } from 'react'

interface DashboardFooter extends FC<HTMLAttributes<HTMLElement>> {
  className?: string
}

const DashboardFooterWrapper = styled.div`
  &.pq-dashboard-footer {
    grid-area: footer;
  }
`

const DashboardFooter: DashboardFooter = (props) => {
  const { children, className, ...rest } = props
  const classes = classNames('pq-dashboard-footer', className || '')
  return (
    <DashboardFooterWrapper className={classes} {...rest}>
      {children}
    </DashboardFooterWrapper>
  )
}

export { DashboardFooter }
