import Head from 'next/head'
import { IHeadCommonProps } from './types'
import { PropsWithChildren } from 'react'
import { defineMessages } from '@features/core/react-intl/define-messages'
import { useIntl } from '@palqee/intl'

const HeadCommon = (props: PropsWithChildren<IHeadCommonProps>) => {
  const { metaTitleFormatMessage, children } = props

  const { message, values } = metaTitleFormatMessage ?? {}
  const { id } = message ?? {}
  const intl = useIntl()

  return (
    <Head>
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width"
      />
      {metaTitleFormatMessage && (
        <title>{intl.formatMessage(defineMessages[id], values)}</title>
      )}
      {children}
    </Head>
  )
}

export { HeadCommon }
