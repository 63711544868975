import styled from '@emotion/styled'
import { classNames } from '@common/utils/helpers'
import Button from '@common/components/button'

interface BreadcrumbProps extends React.HTMLAttributes<HTMLElement> {
  className?: string | undefined
  items?: string[]
}

type Breadcrumb = React.FC<React.PropsWithChildren<BreadcrumbProps>>

const BreadcrumbWrapper = styled.div`
  &.pq-breadcrumb {
    & .pq-breadcrumb-btn {
      cursor: default !important;
      span {
        font-size: 0.75rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #8a91a5;
        &:after {
          content: '/';
          margin: 0 5px;
        }
        &:last:after {
          content: '';
        }
      }
    }

    & .pq-breadcrumb-btn:last-child {
      span:after {
        content: '';
      }
    }

    span {
      &:before {
        &:last-of-type {
          margin-left: 5px;
        }
      }
    }
  }
`

const Breadcrumb: Breadcrumb = (props) => {
  const { className, items, ...rest } = props
  const classes = classNames('pq-breadcrumb', className || '')

  return (
    <BreadcrumbWrapper className={classes} {...rest}>
      {items.map((item, index) => (
        <Button
          as="a"
          data-cy="breadcrumb-title"
          key={`item-${index}`}
          className="pq-breadcrumb-btn"
        >
          {item}
        </Button>
      ))}
    </BreadcrumbWrapper>
  )
}

export default Breadcrumb
