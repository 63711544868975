import * as React from 'react'
import styled from '@emotion/styled'
import { classNames } from '@common/utils/helpers'

interface SidebarProps extends React.HTMLAttributes<HTMLElement> {
  className?: string | undefined
}

type Sidebar = React.PropsWithChildren<SidebarProps>

const SidebarWrapper = styled.div`
  &.pq-sidebar {
    grid-area: sidebar;
    background-color: #1a3154;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    // fix: https://palqee.atlassian.net/browse/PS-77
    // on smaller screens dropdown would
    // push page down creating white content
    min-height: 700px;

    .logo {
      margin-top: 30px;
      width: 160px;
      height: 40px;
    }
  }
`

const Sidebar = (props: Sidebar) => {
  const { children, className } = props
  const classes = classNames('pq-sidebar', className || '')
  return <SidebarWrapper className={classes}>{children}</SidebarWrapper>
}

export { Sidebar }
